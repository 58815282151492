import React, { useState, useRef, useEffect } from "react";
import { Text } from "@website-builder/ui/shared/elements";

import {
	AccordionWrapper,
	AccordionItemWrapper,
	AccordionItemHeading,
	AccordionItemContent,
	Caret,
} from "./styles";

const PlusMinus = ({ isExpanded }) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			alt=""
			aria-hidden="true"
			className="plus-minus-svg"
		>
			{isExpanded ? (
				<path d="M1 8L15 8" stroke="#05082B" strokeLinecap="round" />
			) : (
				<>
					<path d="M8 1V15" stroke="#05082B" strokeLinecap="round" />
					<path d="M1 8L15 8" stroke="#05082B" strokeLinecap="round" />
				</>
			)}
		</svg>
	);
};

const AccordionItem = ({
	heading,
	headingHtmlTag = "h6",
	isHeadingALink = false,
	content,
	icon,
	showItemTopSeparator,
	accordionHeadingClassName,
	accordionContentClassName,
	richTextContent,
	onExpand,
	showItems,
	isDarkBg,
	index,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const contentRef = useRef(null);
	useEffect(() => {
		// If the accordion content contains any buttons or links, they're not focusable when the accordion is collapsed.
		if (contentRef.current) {
			if (!isExpanded) {
				contentRef.current.querySelectorAll("button, a").forEach((element) => {
					element.setAttribute("tabindex", "-1");
				});
			} else {
				contentRef.current.querySelectorAll("button, a").forEach((element) => {
					element.removeAttribute("tabindex");
				});
			}
		}
	}, [contentRef.current, isExpanded]);
	return (
		<AccordionItemWrapper className={setClassName(showItems, index)}>
			{isHeadingALink ? (
				<AccordionItemHeading
					className={accordionHeadingClassName}
					showItemTopSeparator={showItemTopSeparator}
					aria-expanded={isExpanded}
					isExpanded={isExpanded}
					isDarkBg={isDarkBg}
				>
					{React.isValidElement(heading) && heading}
				</AccordionItemHeading>
			) : (
				<>
					<AccordionItemHeading
						className={accordionHeadingClassName}
						showItemTopSeparator={showItemTopSeparator}
						onClick={() => {
							setIsExpanded(!isExpanded);
							if (!isExpanded) onExpand(heading);
						}}
						aria-expanded={isExpanded}
						isExpanded={isExpanded}
						applyColorWhenExpanded={icon === "caret"}
						isDarkBg={isDarkBg}
					>
						{React.isValidElement(heading) ? (
							heading
						) : (
							<Text variant="headline_6" tag={headingHtmlTag}>
								{heading}
							</Text>
						)}
						{icon === "caret" ? (
							<Caret isExpanded={isExpanded} />
						) : icon === "plusMinus" ? (
							<PlusMinus isExpanded={isExpanded} />
						) : (
							""
						)}
					</AccordionItemHeading>

					<AccordionItemContent
						isExpanded={isExpanded}
						className={isExpanded ? accordionContentClassName : ""}
						isDarkBg={isDarkBg}
						aria-hidden={!isExpanded}
						ref={contentRef}
					>
						{richTextContent ? (
							<Text
								className="dangerous-html-styles rich-text-wrapper"
								variant="paragraph_S"
								tag="div"
								dangerouslySetInnerHTML={{ __html: content }}
							/>
						) : React.isValidElement(content) ? (
							content
						) : (
							<Text variant="paragraph_S">{content}</Text>
						)}
					</AccordionItemContent>
				</>
			)}
		</AccordionItemWrapper>
	);
};

const setClassName = (itemsToShow, index) => {
	if (itemsToShow - 1 >= index) {
		return "show";
	} else {
		return "hide";
	}
};

const Accordion = ({
	accordionItems,
	htmlTagForItemHeading = "h6",
	icon = false,
	showItemTopSeparator = true,
	richTextContent = false,
	accordionHeadingClassName = null,
	accordionContentClassName = null,
	onExpand = () => {},
	showItems,
	isDarkBg,
	...restProps
}) => {
	if (!Array.isArray(accordionItems) || accordionItems.length === 0) {
		return <></>;
	}
	showItems = showItems ? showItems : accordionItems?.length;
	return (
		<AccordionWrapper {...restProps}>
			{accordionItems.map((item, index) => (
				<AccordionItem
					showItemTopSeparator={showItemTopSeparator}
					accordionHeadingClassName={accordionHeadingClassName}
					accordionContentClassName={accordionContentClassName}
					heading={item.heading}
					headingHtmlTag={htmlTagForItemHeading}
					isHeadingALink={item.isHeadingALink}
					content={item.content}
					onExpand={onExpand}
					icon={icon}
					richTextContent={richTextContent}
					index={index}
					showItems={showItems}
					isDarkBg={isDarkBg}
				/>
			))}
		</AccordionWrapper>
	);
};

export default Accordion;
