import styled from "styled-components";
import { MOBILE_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
export const AccordionWrapper = styled.div`
	margin: auto;
	${(props) =>
		props.showTopSeparator &&
		`border-top: 1px solid var(--secondary-gray-medium);`}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		width: 100%;
	}
`;

export const AccordionItemHeading = styled.button`
	background: transparent;
	box-shadow: none;
	width: 100%;
	border: none;
	text-align: left;
	// removes default button styling

	cursor: pointer;
	padding: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;

	.plus-minus-svg {
		min-width: 16px;
	}

	${({ isDarkBg }) =>
		isDarkBg &&
		`
    &.accordion-heading {
      h3{
        color: var(--theme-light-font-color);
      }
      svg > path {
        stroke: var(--theme-light-font-color);
      }
    }   
  `}

	${(props) =>
		props.isExpanded && props.showItemTopSeparator
			? `border-bottom: 1px solid var(--secondary-gray-medium);`
			: ""}

  color: ${(props) =>
		props.isExpanded && props.applyColorWhenExpanded
			? "var(--secondary-purple-300);"
			: "var(--primary-neutral-nightshade-800);"}
`;

export const AccordionItemWrapper = styled.div`
	border-bottom: 1px solid var(--secondary-gray-medium);
	&.show {
		display: block;
	}
	&.hide {
		display: none;
	}
`;

export const AccordionItemContent = styled.div`
	${(props) => (props.isExpanded ? `padding: 1.5rem;` : "")}
	opacity: ${(props) => (props.isExpanded ? "1" : "0")};
	max-height: ${(props) => (props.isExpanded ? "min-content" : "0")};
	overflow: hidden;
	margin: 0;
	transition: all 200ms linear;
	will-change: opacity, max-height;
	${({ isDarkBg }) =>
		isDarkBg &&
		`
    &.accordion-content p{
      color: var(--theme-light-font-color);
    }   
  `}

	.dangerous-html-styles {
		& * {
			font-family: inherit;
			line-height: inherit;
			font-size: inherit;
			letter-spacing: inherit;
			text-transform: inherit;

			a {
				text-decoration: none;
				font-weight: var(--fw-haffer-xh-2);
				color: ${({ isDarkBg }) =>
					isDarkBg
						? "var(--theme-light-font-color, var(--secondary-purple-100))"
						: "var(--theme-dark-font-color, var(--secondary-purple-300))"};
			}
			a:hover {
				text-decoration: underline;
			}
		}

		${({ isDarkBg }) =>
			isDarkBg &&
			`
      ul {
        color: var(--theme-light-font-color, var(--primary-neutral-white));
      }
    `}
	}
`;

export const Caret = styled.i`
	width: 0;
	height: 0;
	display: inline-block;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;
	${(props) =>
		props.isExpanded
			? "border-top: 0.5rem solid var(--secondary-purple-300);"
			: "border-top: 0.5rem solid var(--primary-neutral-nightshade-800);"}
	border-bottom: none;
	transition: all 200ms linear;

	${(props) => (props.isExpanded ? `transform: scaleY(-1);` : "")}
`;
